window.angular.module('MyHippoProducer', [
    'ngSanitize', 'ngRoute', 'ngCookies', 'ui.router', 'ui.router.state.events', 'ui.bootstrap', 'toaster', 'ngMessages',
    'ngAnimate', 'ui.select', 'formly', 'formlyBootstrap', 'ngFileUpload', 'monospaced.elastic', 'ui.mask', 'angularSpinners',
    'auth0.auth0', 'angular-jwt', 'ng-showdown', 'ngMaterial', 'duScroll',
    'MyHippoCommons.Directives',
    'MyHippoCommons.Workflow',
    'MyHippoFormly.Directives',
    'MyHippoProducer.Controllers',
    'MyHippoProducer.Components',
    'MyHippoProducer.Services',
    'MyHippoProducer.Filters'
]).config(function ($httpProvider, $locationProvider) {
    $locationProvider.html5Mode(false).hashPrefix('');
    $httpProvider.defaults.withCredentials = true;
}).run(function ($rootScope, $trace, $transitions, $timeout, $window, $state, $location, $stateParams,
    spinnerService, PolicyService, toaster) {
    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams, error) {
        if (toState.name == 'portal.policyDetailsV2') {
            if (toParams.mode === 'edit' && !PolicyService.policyInfo) {
                toaster.pop('error','Error', 'Missing Transaction. Transitioning to view mode');
                $state.go('portal.policyDetailsV2', { id: toParams.id, mode: 'view' });
            }
        } else if (_.get(error, 'detail.data')) {
            toaster.error(error.detail.data);
        }
    });

    if (!window.location.hostname.startsWith('producer')) {
        const metaTag = document.querySelector('meta[name="robots"]');
        if (!metaTag) {
            const newMeta = document.createElement('meta');
            newMeta.name = 'robots';
            newMeta.content = 'noindex, nofollow';
            document.head.appendChild(newMeta);
        }
    }

    $transitions.onStart({}, function (trans) {
        const transitionToPageName = trans.to().name;
        if (transitionToPageName !== 'portal.policyCreate') {
            spinnerService.show('globalSpinner');
            trans.promise.finally(() => spinnerService.hide('globalSpinner'));
        }
    });

    $transitions.onError({}, function (transition) {
        transition.promise.finally(() => spinnerService.hide('globalSpinner'));
    });

    $transitions.onSuccess({}, function (trans) {
        $rootScope.pageClassNames = trans.$to().name.split('.').map(w => w.toLowerCase() + '-page').join(' ');
        $window.scrollTo(0, 0); // scroll to top on page change
    });

    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;
});
